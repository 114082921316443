import React, { useState, useEffect, useReducer , useContext, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
}));

const BulkMessageView = () => {
    const classes = useStyles();
    const { user } = useContext(AuthContext);
    const { bulkMessageId } = useParams();
    const history = useHistory();

    return (
        <MainContainer className={classes.mainContainer}>
            <MainHeader>
                <Title>{i18n.t("bulkMessageView.title")}</Title>
                <MainHeaderButtonsWrapper>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => history.goBack()}
                    >
                        {i18n.t("bulkMessageView.buttons.back")}
                    </Button>
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper className={classes.mainPaper} variant="outlined">
                <strong>Título da mensagem</strong>
                Titulo da mensagem aqui
            
                <br />
                <strong>Mensagem</strong>
                Aqui ficará a mensagem enviada
            
                <br />
                <br />
                <strong>Mensagens enviadas: 10</strong>

                <strong>Mensagens enviadas com erro: 10</strong>

                <strong>Mensagens Recebidas: 10</strong>

                <strong>Mensagens Lidas: 10</strong>

                <br />
                <br />
                
                <Button
                    variant="contained"
                    color="primary">
                    Re-enviar Menssagem com Erros
                </Button>

                <Button
                    variant="contained"
                    color="primary">
                    Parar Envio
                </Button>

                <Button
                    variant="contained"
                    color="primary">
                    Iniciar Envio
                </Button>

                <Button
                    variant="contained"
                    color="primary">
                    Salvar data de Agendamento de Envio
                </Button>

            </Paper>
        </MainContainer>
    )
}

export default BulkMessageView;