import React from "react";

const BulkMessageStatusIcon = ({ bulkMessage }) => {
	const statusName = {
		0: {
			name: "-",
			color: "#FFFFFF"
		},
		1: {
			name: "Não Enviado",
			color: "#e9e9e9"
		},
		2: {
			name: "Enviando",
			color: "#ffc698"
		},
		3: {
			name: "Enviado",
			color: "#9cffc1"
		},
		4: {
			name: "Preparando Envio",
			color: "#c0ccff"
		}
	};
	const status = statusName[bulkMessage.status] ? statusName[bulkMessage.status]: statusName[0];
	const styleStatus = {
		backgroundColor: status.color,
		borderRadius: '8px',
		display: 'inline-block',
		padding: "2px 8px 0px 8px",
		marginLeft: '2px',
		marginRight: '2px',
		fontWeight: "bold"
	};

    return <span style={styleStatus}>{status.name}</span>
}

export default BulkMessageStatusIcon;