import React, { useState, useEffect, useReducer, useContext, useCallback } from "react";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainContainer from "../../components/MainContainer";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import BulkMessageModal from "../../components/BulkMessages/BulkMessageModal";
import { Can } from "../../components/Can";
import { AuthContext } from "../../context/Auth/AuthContext";
import BulkMessageStatusIcon from "../../components/BulkMessages/statusIcon";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import moment from "moment/moment";
import ConfirmationModal from "../../components/ConfirmationModal";
import { PlayCircle, StopCircle, Source } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_BULKMESSAGES") {
    const bulkMessages = action.payload;
    const newBulkMessages = [];

    bulkMessages.forEach((bulkMessage) => {
      const bulkMessageIndex = state.findIndex((q) => q.id === bulkMessage.id);
      if (bulkMessageIndex !== -1) {
        state[bulkMessageIndex] = bulkMessage;
      } else {
        newBulkMessages.push(bulkMessage);
      }
    });

    return [...state, ...newBulkMessages];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const BulkMessage = () => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const [bulkMessages, dispatch] = useReducer(reducer, []);
  const [loading, setLoading] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [deletingBulkMessage, setDeletingBulkMessage] = useState(null);
  const [bulkMessageModalOpen, setBulkMessageModalOpen] = useState(false);
  const [selectedBulkMessageId, setSelectedBulkMessageId] = useState(null);

  const fetchBulkMessages = useCallback(async () => {
    try {
      const { data } = await api.get("/bulkMessages");
      dispatch({ type: "LOAD_BULKMESSAGES", payload: data });
    } catch (err) {
      toastError(err);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchBulkMessages();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [fetchBulkMessages]);

  const handleCloseBulkMessage = () => {
    fetchBulkMessages();
    setSelectedBulkMessageId(null);
    setBulkMessageModalOpen(false);
  };

  const handleOpenPageCreatedMessage = () => {
    setSelectedBulkMessageId(null);
    setBulkMessageModalOpen(true);
  };

  const handleEditBulkMessage = (bulkMessageId) => {
    setSelectedBulkMessageId(bulkMessageId);
    setBulkMessageModalOpen(true);
  };

  const handleDeleteBulkMessage = async (bulkMessageId) => {
    try {
      await api.delete(`/bulkMessages/${bulkMessageId}`);
      toast.success(i18n.t("bulkMessages.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingBulkMessage(null);
    fetchBulkMessages();
    dispatch({ type: "RESET" });
  };

  const handleStopMessages = async (bulkMessageId) => {
    try {
			await api.post(`/bulkMessages/stopSendMessage/${bulkMessageId}`);
			toast.success(i18n.t("bulkMessages.successStopMessage"));
      fetchBulkMessages();
		} catch (err) {
			toastError(err);
		}
  }

  const handleSendMessages = async (bulkMessageId) => {
    try {
			await api.post(`/bulkMessages/startSendMessage/${bulkMessageId}`);
			toast.success(i18n.t("bulkMessages.successSendMessage"));
      fetchBulkMessages();
		} catch (err) {
			toastError(err);
		}
  }

  const handleViewMessage = async (bulkMessageId) => {
    alert("VIEWWWW " + bulkMessageId );
  }

  const numbersStatusOptions = [1, 2, 3, 4];

  return (
    <MainContainer className={classes.mainContainer}>
      <ConfirmationModal        
        title={
          deletingBulkMessage &&
          `${i18n.t("bulkMessages.confirmationModal.deleteTitle")}`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeleteBulkMessage(deletingBulkMessage.id)}
      >
        {i18n.t("bulkMessages.confirmationModal.deleteMessage")}
      </ConfirmationModal>

      <BulkMessageModal
        open={bulkMessageModalOpen}
        onClose={handleCloseBulkMessage}
        aria-labelledby="form-dialog-title"
        bulkMessageId={selectedBulkMessageId}
      ></BulkMessageModal>

      <MainHeader>
        <Title>{i18n.t("bulkMessages.title")}</Title>
        <MainHeaderButtonsWrapper>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenPageCreatedMessage}
          >
            {i18n.t("bulkMessages.buttons.add")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper className={classes.mainPaper} variant="outlined">

        <div align="center">
          { numbersStatusOptions.map(number => (
            <BulkMessageStatusIcon key={number} bulkMessage={{ status:number }}></BulkMessageStatusIcon>
          ))}
        </div>

        <br />

        <Table size="small">
          <TableHead>
            <TableRow style={{ background:"#CCCCCC" }}>
              <TableCell align="center">{i18n.t("bulkMessages.table.status")}</TableCell>
              <TableCell>{i18n.t("bulkMessages.table.title")}</TableCell>
              <TableCell align="center">{i18n.t("bulkMessages.table.dateSend")}</TableCell>
              <TableCell align="center">{i18n.t("bulkMessages.table.totalSend")}</TableCell>
              <TableCell align="center">{i18n.t("bulkMessages.table.totalRead")}</TableCell>
              <TableCell align="center">{i18n.t("bulkMessages.table.totalErros")}</TableCell>
              <TableCell align="center">{i18n.t("bulkMessages.table.totalContacts")}</TableCell>
              <TableCell align="center">{i18n.t("bulkMessages.table.actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {bulkMessages.map((bulkMessage) => (
                <TableRow key={bulkMessage.id}>
                  <TableCell align="center">
                    <BulkMessageStatusIcon bulkMessage={bulkMessage}></BulkMessageStatusIcon>
                  </TableCell>
                  <TableCell>{bulkMessage.title}</TableCell>
                  <TableCell align="center">{bulkMessage.dateSend ? moment(bulkMessage.dateSend).format("DD/MM/YYYY HH:mm") : '-'}</TableCell>
                  <TableCell align="center">{bulkMessage.contactsSendCount}</TableCell>
                  <TableCell align="center">{bulkMessage.contactsReadCount}</TableCell>
                  <TableCell align="center">{bulkMessage.contactsErrosCount}</TableCell>
                  <TableCell align="center">{bulkMessage.allContactsToSendCount}</TableCell>
                  <TableCell align="right">
                    {(bulkMessage.status==1) && (
                      <IconButton
                        size="small"
                        title="Iniciar enviar das mensagem"
                        onClick={() => handleSendMessages(bulkMessage.id)}
                        >
                        <PlayCircle />
                      </IconButton>
                    )}

                    {(bulkMessage.status==2 || bulkMessage.status==4) && (
                      <IconButton
                        size="small"
                        title="Parar envio das mensagem"
                        onClick={() => handleStopMessages(bulkMessage.id)}
                      >
                        <StopCircle />
                      </IconButton>
                    )}

                    {/* 
                    // TODO: detalhes da mensagem enviada com todos os detalhes com algumas funções extra
                    //        desativei para focar em outros recursos

                    {(bulkMessage.status==2 || bulkMessage.status==3 || bulkMessage.status==4) && (
                      <Link
                        to={() => `/bulkMessages/${bulkMessage.id}`}
                        color="primary"
                        component={RouterLink}>
                        <Source />
                      </Link>
                    )}
                    */}

                    {(bulkMessage.status==1) && (
                      <>
                        <IconButton
                          size="small"
                          title="Editar mensagem"
                          onClick={() => handleEditBulkMessage(bulkMessage.id)}
                          >
                          <EditIcon />
                        </IconButton>

                        <Can
                        role={user.profile}
                        perform="bulkMessage-page:deleteBulkMessage"
                        yes={() => (
                          <IconButton
                            size="small"
                            title="Excluir mensagem"
                            onClick={(e) => {
                              setConfirmModalOpen(true);
                              setDeletingBulkMessage(bulkMessage);
                            }}
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        )}
                        />
                      </>
                    )}

                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={7} />}
            </>
          </TableBody>
        </Table>

      </Paper>
    </MainContainer>
  );
};

export default BulkMessage;
