import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import { Formik, FieldArray, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},

	extraAttr: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
}));

const BulkMessageSchema = Yup.object().shape({
	title: Yup.string().required("Required"),
	message: Yup.string().required("Required"),
});

const BulkMessageModal = ({ open, onClose, bulkMessageId, initialValues, onSave }) => {
	const classes = useStyles();
	const isMounted = useRef(true);
	const formRef = useRef();

	const initialState = {
		title: "",
		message: "",
		status: 1, // 1: nao enviado
		dateSend: null,
	};

	const [bulkMessage, setBulkMessage] = useState(initialState);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		const fetchBulkMessage = async () => {
			if (initialValues) {
				setBulkMessage(prevState => {
					return { ...prevState, ...initialValues };
				});
			}

			if (!bulkMessageId) return;

			try {
				const { data } = await api.get(`/bulkMessages/${bulkMessageId}`);
				if (isMounted.current) {
					setBulkMessage(data);
				}
			} catch (err) {
				toastError(err);
			}
		};

		fetchBulkMessage();
	}, [bulkMessageId, open, initialValues]);

	const handleClose = () => {
		setBulkMessage(initialState);
		onClose();
	};

	const handleSaveBulkMessage = async values => {
		try {
			if (bulkMessageId) {
				await api.put(`/bulkMessages/${bulkMessageId}`, values);
				handleClose();
			} else {
				const { data } = await api.post("/bulkMessages", values);
				if (onSave) {
					onSave(data);
				}
				handleClose();
			}
			toast.success(i18n.t("bulkMessageModal.success"));
		} catch (err) {
			toastError(err);
		}
	};

	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
				<DialogTitle id="form-dialog-title">
					{bulkMessageId
						? `${i18n.t("bulkMessageModal.title.edit")}`
						: `${i18n.t("bulkMessageModal.title.add")}`}
				</DialogTitle>
				<Formik
					innerRef={formRef}
					initialValues={bulkMessage}
					enableReinitialize={true}
					validationSchema={BulkMessageSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveBulkMessage(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ values, errors, touched, isSubmitting }) => (
						<Form>
							<DialogContent dividers>
								<Typography variant="subtitle1" gutterBottom>
									{i18n.t("bulkMessageModal.form.mainInfo")}
								</Typography>
								<Field
									as={TextField}
									label={i18n.t("bulkMessageModal.form.title")}
									name="title"
									fullWidth
									error={touched.title && Boolean(errors.title)}
									helperText={touched.title && errors.title}
									variant="outlined"
									margin="dense"
									className={classes.textField}
								/>
								<div>
									<Field
										as={TextField}
										label={i18n.t("bulkMessageModal.form.message")}
										multiline
										minRows={5}
										fullWidth
										name="message"
										error={touched.message && Boolean(errors.message)}
										helperText={touched.message && errors.message}
										variant="outlined"
										margin="dense"
									/>
								</div>
							</DialogContent>
							<DialogActions>
								<Button
									type="button"
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
									>
									{i18n.t("bulkMessageModal.buttons.cancel")}
								</Button>

								<Button
									type="submit"
									disabled={isSubmitting}
									variant="contained"
									style={{background:"#0063cc",color:"#FFFFFF"}}
									className={classes.btnWrapper}
									>
									{bulkMessageId
										? `${i18n.t("bulkMessageModal.buttons.okEdit")}`
										: `${i18n.t("bulkMessageModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
								{/*
								<Button
									type="button"
									disabled={isSubmitting}
									variant="contained"
									color="primary"
									onClick={handleSaveAndSend}
									>
									{i18n.t("bulkMessageModal.buttons.okSaveSend")}
								</Button>
								*/}
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default BulkMessageModal;
